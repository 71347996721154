import { template as template_176fb162f5ef41e5b23eb68fb70b40e7 } from "@ember/template-compiler";
const FKLabel = template_176fb162f5ef41e5b23eb68fb70b40e7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
