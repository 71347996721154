import { template as template_4155df7546334ee7b5b8984a9f524978 } from "@ember/template-compiler";
const FKControlMenuContainer = template_4155df7546334ee7b5b8984a9f524978(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
