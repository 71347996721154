import { template as template_b2e7593d0a9845689a1e7e62cfe9d2b5 } from "@ember/template-compiler";
const FKText = template_b2e7593d0a9845689a1e7e62cfe9d2b5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
