import { template as template_20ae47fac31f45ffb0980b342ce41036 } from "@ember/template-compiler";
const SidebarSectionMessage = template_20ae47fac31f45ffb0980b342ce41036(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
